<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h4 class="text-center">Thank you.</h4>
          <h4 class="text-center">Your password has been reset successfully. Please login in app using new password</h4>
          <h4 class="text-center">[ Please close browser.]</h4>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      value1: ''
    }
  }
}

</script>

